// constants
import Web3 from "web3";
import SmartContract from "../../contracts/CryptoCuntsClub.json";
// log
import { fetchData } from "../data/dataActions";

//walletconnect code
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3Modal from "web3modal";

// set chain id and rpc mapping in provider options
const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      rpc: {
        1: 'https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'
      },
      chainId: 1
    }
  }
}

const web3Modal = new Web3Modal({
  network: "mainnet", // optional
  cacheProvider: true, // optional
  providerOptions // required
});

const connectRequest = () => {
  return {
    type: "CONNECTION_REQUEST",
  };
};

const connectSuccess = (payload) => {
  return {
    type: "CONNECTION_SUCCESS",
    payload: payload,
  };
};

const connectFailed = (payload) => {
  return {
    type: "CONNECTION_FAILED",
    payload: payload,
  };
};

const updateAccountRequest = (payload) => {
  return {
    type: "UPDATE_ACCOUNT",
    payload: payload,
  };
};

export const connect = () => {
  return async (dispatch) => {
    dispatch(connectRequest());
    if (window.screen.width <= 768)
    {
      //wallet connect code
    const provider = await web3Modal.connect();
    await web3Modal.toggleModal();

    // regular web3 provider methods
    const newWeb3 = new Web3(provider);
    try{
      const accounts = await newWeb3.eth.getAccounts();
      const networkId = await newWeb3.eth.net.getId();
    if (networkId == 1)
    {
    const SmartContractObj = new newWeb3.eth.Contract(
      SmartContract.abi,
      "0x8b63608aea9c2301c74db0a74a6de5bf416cb248"
    );
    dispatch(
      connectSuccess({
        account: accounts[0],
        smartContract: SmartContractObj,
        web3: newWeb3,
      })
    );

    // Add listeners start
    // Subscribe to accounts change
    provider.on("accountsChanged", (accounts) => {
      dispatch(updateAccount(accounts[0]));
    });
   
    // Subscribe to chainId change
    provider.on("chainChanged", () => {
      window.location.reload();
    });
  }
  else{
    dispatch(connectFailed("Change network to ETH Mainnet."));
  }
  }
   catch (err) {
  dispatch(connectFailed("Something went wrong."));
}

    //wallet connect code
    }
    else
    {
      if (window.ethereum) {
        let web3 = new Web3(window.ethereum);
        try {
          const accounts = await window.ethereum.request({
            method: "eth_requestAccounts",
          });
          const networkId = await window.ethereum.request({
            method: "net_version",
          });
           if (networkId == 1) {
            const SmartContractObj = new web3.eth.Contract(
              SmartContract.abi,
              "0x8b63608aea9c2301c74db0a74a6de5bf416cb248"
            );
            dispatch(
              connectSuccess({
                account: accounts[0],
                smartContract: SmartContractObj,
                web3: web3,
              })
            );
            // Add listeners start
            window.ethereum.on("accountsChanged", (accounts) => {
              console.log('acc change web');
              dispatch(updateAccount(accounts[0]));
            });
            window.ethereum.on("chainChanged", () => {
              window.location.reload();
            });
            // Add listeners end
          } else {
            dispatch(connectFailed("Change network to ETH Mainnet."));
          }
        } catch (err) {
          console.log(err);
          dispatch(connectFailed("Something went wrong."));
        }
      } else {
        dispatch(connectFailed("Install/Enable Metamask Extension."));
        window.open('https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en#:~:text=MetaMask%20is%20an%20extension%20for,can%20read%20from%20the%20blockchain.', '_blank');
      }
    }
    
  };
};

export const updateAccount = (account) => {
  return async (dispatch) => {
    dispatch(updateAccountRequest({ account: account }));
    dispatch(fetchData(account));
  };
};
