import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import connectImg from "./assets/images/connect.png"; //when user is not connected
import connectedImg from "./assets/images/connected.png"; //when user connects their wallet
import mintingImg from "./assets/images/minting.png"; //when minting is in progress
import mintedImg from "./assets/images/minted.png"; //when mints nft successfully
import errorImg from "./assets/images/error.png"; //when there is any error

export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: #72f595;
  padding: 10px;
  font-weight: bold;
  color: #0f1922;
  width: 150px;
  cursor: pointer;
  letter-spacing: 1px;
    font-size: 16px;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  //padding: 24px;
  @media (min-width: 767px) {
    flex-direction: row;
  };
  @media (max-width: 767px) {
    padding: 0px;
  }
`;

export const StyledImg = styled.img`
  width: 250px;
  height: 250px;
  @media (max-width: 767px) {
    width: 200px;
    height: 200px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [feedback, setFeedback] = useState("Maybe it's your lucky day.");
  const [showImg, showImgURL] = useState(connectImg);
  const [claimingNft, setClaimingNft] = useState(false);
  const [mAmount, updateMAmount] = useState(1);

  const claimNFTs = (_amount) => {
    if (_amount <= 0) {
      return;
    }
    showImgURL(mintingImg);
    setFeedback("Minting your "+_amount+" CryptoCunt...");
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(_amount)
      .send({
        gasLimit: 285000 * _amount,
        from: blockchain.account,
       value: data.cost * _amount,
      })
      .once("error", (err) => {
        showImgURL(errorImg);
        setFeedback("Sorry, either you rejected the transaction or the error occurred.");
        setClaimingNft(false);
        setTimeout( () => {
          showImgURL(connectedImg);
          setFeedback("Try to mint again. ");
      }, 2500);

      setTimeout( () => {
        // showImgURL(mintingImg);
        setFeedback("Maybe it's your lucky day. ");
    }, 5000);
      })
      .then((receipt) => {
        showImgURL(mintedImg);
        setFeedback(
          "Congrats, you successfully minted a CryptoCunt NFT. Welcome to the CLUB! To view your CryptoCunt Club NFT go to opensea.io"
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));

        setTimeout( () => {
          showImgURL(connectedImg);
          setFeedback("Mint More. ");
      }, 2500);

      setTimeout( () => {
        // showImgURL(mintingImg);
        setFeedback("Maybe it's your lucky day. ");
    }, 5000);
      });
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
      showImgURL(connectedImg);
    }
  };

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
    <s.Screen style={{ /* backgroundColor: "var(--black)" */ }}>
      <s.Container flex={1} ai={"center"} style={{ /* padding: 24  */ }}>
      <s.SpacerMedium />
        <s.TextTitle
          style={{ textAlign: "center", fontWeight: "bold", color: "rgb(114, 245, 149)" }}
        >
          Mint a CryptoCunt
        </s.TextTitle>
        <s.SpacerMedium />
        <ResponsiveWrapper flex={1} style={{ /* padding: 24  */}}>
          <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg alt={"CC"} src={showImg} />
            {/* <s.SpacerMedium /> */}
            {/* <s.TextTitle
              style={{ textAlign: "center", fontSize: 35, fontWeight: "bold" }}
            >
              {data.totalSupply}/{data.maxMintAmount}
            </s.TextTitle> */}
            <s.Container
            flex={1}
            jc={"center"}
            ai={"center"}
            style={{ /* backgroundColor: "#383838", padding: 24 */}}
          >
            {Number(data.totalSupply) === 10000 ? (
              <>
              {/* <s.TextTitle
              style={{ textAlign: "center", fontSize: 35, fontWeight: "bold" }}
            >
              {data.totalSupply}/{data.maxMintAmount}
            </s.TextTitle> */}
                <s.TextTitle style={{ textAlign: "center" }}>
                The minting is closed.
                </s.TextTitle>
                <s.SpacerSmall />
                <s.TextDescription style={{ textAlign: "center" }}>
                  You can still find a CryptoCunt on{" "}
                  <a
                    target={"_blank"}
                    href={"https://opensea.io/collection/cryptocuntsclub"}
                    rel="noreferrer"
                  >
                    opensea.io
                  </a>
                </s.TextDescription>
              </>
            ) : (
              <>
                {/* <s.TextTitle style={{ textAlign: "center" }}>
                  1 CryptoCunt costs {data.cost/1000000000000000000} ETH.
                </s.TextTitle> */}
                {/* <s.SpacerXSmall />
                <s.TextDescription style={{ textAlign: "center" }}>
                  Excluding gas fee.
                </s.TextDescription>
                <s.SpacerSmall /> */}
                {/* <s.TextDescription style={{ textAlign: "center" }}>
                  {feedback}
                </s.TextDescription> */}
                <s.SpacerXSmall />
                {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <s.Container ai={"center"} jc={"center"}>
                    {/* <s.TextDescription style={{ textAlign: "center" }}>
                      Connect to the ETH Mainnet network
                    </s.TextDescription> */}
                    {/* <s.SpacerSmall /> */}
                    <StyledButton
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                      CONNECT
                    </StyledButton>
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription style={{ textAlign: "center" }}>
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <s.Container 
                  flex={1} ai={"center"} jc={"center"}>

<s.TextTitle
              // style={{ textAlign: "center", fontSize: 35, fontWeight: "bold" }}
              className="textTitle"
            >
              {data.totalSupply}/{data.maxMintAmount}
            </s.TextTitle>
                    <s.TextTitle style={{ textAlign: "center" }}>
                  1 CryptoCunt costs {data.cost/1000000000000000000} ETH.
                </s.TextTitle>
                <s.SpacerXSmall />
                {/* <s.TextDescription style={{ textAlign: "center" }}>
                  Excluding gas fee.
                </s.TextDescription> */}
                <s.TextDescription style={{ textAlign: "center" }}>
                  {feedback}
                </s.TextDescription>
                <s.SpacerMedium />
                <input type="number" name="amount" min="1" value={mAmount} onChange={(e) => updateMAmount(e.target.value)} required></input>
                <s.SpacerMedium />
                    <StyledButton
                      disabled={claimingNft ? 1 : 0}
                      onClick={(e) => {
                        e.preventDefault();
                        claimNFTs(mAmount);
                        getData();
                      }}
                    >
                      {claimingNft ? "BUSY" : "Mint"}
                    </StyledButton>
                  </s.Container>
                )}
              </>
            )}
          </s.Container>
          </s.Container>
          <s.SpacerMedium />
          
        </ResponsiveWrapper>
        <s.SpacerSmall />
        {/* <s.Container jc={"center"} ai={"center"} style={{ width: "70%" }}>
          <s.TextDescription style={{ textAlign: "center" }}>
            Please make sure you are connected to the right network (ETH Mainnet) and the correct address. Please note: Once you make the
            purchase, you cannot undo this action.
          </s.TextDescription>
          <s.SpacerSmall />
          <s.TextDescription style={{ textAlign: "center" }}>
            We have set the gas limit to 285000 for the contract to successfully
            mint your NFT. We recommend that you don't change the gas limit.
          </s.TextDescription>
        </s.Container> */}
      </s.Container>
    </s.Screen>
  );
}

export default App;
